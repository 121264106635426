import React from "react";

import "./OurReach.css";
import LazyImage from "../../common/LazyImage/LazyImage";

const OurReach = () => {
  return (
    <div className="our-reach">
      <h2 className="custom-h2">Our Reach</h2>
      <LazyImage
        src={require("../../../assets/Placement/OurReach.webp")}
        alt="Our Reach"
      />
    </div>
  );
};

export default OurReach;
