import React, { useContext, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./Navbar.css";
import Logo from "../../../assets/Home/collegelogo@2x.png";
import CloseIcon from "../../../assets/Home/close.svg";
import MenuIcon from "../../../assets/Home/menu.svg";
import MenuArrow from "../../../assets/Home/menu-arrow.svg";
import LazyImage from "../LazyImage/LazyImage";
import navbarLinks from "./NavbarLinks";
import {
  ADMINISTRATIONS,
  ADMISSIONS,
  COMMITTEES,
  DEPARTMENTS,
  FACILITIES,
  HOME,
  PLACEMENTS,
  STUDENTS,
  deptRoutes,
  OLD_WEBSITE,
} from "../../../config/routes";

import { administrationTabs } from "../../../pages/Administrations/Administrations";
import { committeesTabs } from "../../../pages/Committees/Committees";
import { placementSidebarTabs } from "../../Placements/PlacementSidebar";

import { DocsContext } from "../../../App";

const Navbar = () => {
 
  const { width } = useWindowDimensions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const docs = useContext(DocsContext);
  const [nirfLink, setNirfLink] = useState("");

  useEffect(() => {
    let doc = docs.find((x) => x.title === "NIRF");
    if (doc) {
      setNirfLink(doc.upload_file);
    }
  }, [docs]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsSidebarOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const Sidebar = ({ item, depth }) => {
    const [open, setOpen] = useState(false);

    return (
      <div className={`sidebar-tabs-${depth}`}>
        <NavLink
          to={{
            pathname: item.link,
            ...(item.section && { section: item.section }),
          }}
          onClick={() => setIsSidebarOpen(false)}
          style={{ textDecoration: "none", color: "var(--blue)" }}
        >
          {item.name.toUpperCase()}
        </NavLink>
        {!!item.children && (
          <LazyImage
            src={MenuArrow}
            className="menu-arrow"
            alt=""
            onClick={() => setOpen(!open)}
          />
        )}
        {open &&
          !!item.children &&
          item.children.map((item, id) => (
            <Sidebar item={item} depth={depth + 1} key={id} />
          ))}
      </div>
    );
  };

  const NavbarDropDown = ({ page }) => {
    return (
      <div className="navbar-dropdown-container">
        {page === "Administration" && (
          <>
            {administrationTabs.map((tab, i) => (
              <div key={tab}>
                <NavLink to={{ pathname: ADMINISTRATIONS, section: i }}>
                  {tab}
                </NavLink>
              </div>
            ))}
          </>
        )}
        {page === "Departments" && (
          <>
            {deptRoutes.map((route) => (
              // <div key={route.name}>
              //   {route.name === "Computer Engineering" ? (
              //     <a
              //       href="http://cs.mec.ac.in/"
              //       target="_blank"
              //       rel="noreferrer"
              //     >
              //       {route.name}
              //     </a>
              //   ) : (
              //     <NavLink to={route.path}>{route.name}</NavLink>
              //   )}
              // </div>
              <NavLink to={route.path}>{route.name}</NavLink>
            ))}
          </>
        )}
        {page === "Statutory" && (
          <>
            {committeesTabs.map((tab, i) => (
              <div key={tab}>
                <NavLink to={{ pathname: COMMITTEES, section: i }}>
                  {tab}
                </NavLink>
              </div>
            ))}
          </>
        )}
        {page === "Placements" && (
          <>
            {placementSidebarTabs.map((tab, i) => (
              <div key={tab}>
                <NavLink to={{ pathname: PLACEMENTS, section: i }}>
                  {tab}
                </NavLink>
              </div>
            ))}
          </>
        )}
        {page === "Admissions" && (
          <div>

             <NavLink to={{ pathname:'/admissions2024' }}>
          Admission 2024
        </NavLink>

            <NavLink to={{ pathname:'/admissions' }}>
          Admission
        </NavLink>
          </div>
        
        )}
      </div>
    );
  };

  return width <= 720 ? (
    <>
      <div className="mobile-navbar-container">
        <NavLink to="/" className="mec-logo-tab">
          <LazyImage src={Logo} className="mec-logo" alt="" />
        </NavLink>
        {!isSidebarOpen && (
          <LazyImage
            src={MenuIcon}
            className="menu-icon"
            alt=""
            onClick={() => setIsSidebarOpen(true)}
          />
        )}
      </div>
      {isSidebarOpen && (
        <div ref={wrapperRef}>
          <div className="sidebar-container" id="sidebar">
            <LazyImage
              src={CloseIcon}
              className="menu-icon"
              alt=""
              onClick={() => setIsSidebarOpen(false)}
            />
            {navbarLinks.map((item, id) => (
              <Sidebar item={item} depth={0} key={id} />
            ))}
            <div className={`sidebar-tabs-0`}>
              <a
                href={
                  nirfLink || OLD_WEBSITE + "/mec/news_and_events/nirf-2021.pdf"
                }
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsSidebarOpen(false)}
                style={{ textDecoration: "none", color: "var(--blue)" }}
              >
                NIRF
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="navbar-container">
      <NavLink to={HOME} className="mec-logo-tab">
        <LazyImage src={Logo} className="mec-logo" alt="" />
      </NavLink>
      <div className="navbar-inner-container">
        <NavLink
          to={ADMINISTRATIONS}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          ADMINISTRATION
        </NavLink>
        <NavbarDropDown page={"Administration"} />
      </div>
      <div className="navbar-inner-container">
     
          <div
          className="other-tabs"
          activeClassName="active-tabs"
          style={{cursor:'pointer'}}
        >
          <p id="admissions-tab-23">
            ADMISSIONS
          </p>
        </div>
        <NavbarDropDown page={"Admissions"} />
     
    
    {/* <NavLink
          to={ADMISSIONS}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          ADMISSIONS

        </NavLink> */}
      </div>
      <div className="navbar-inner-container">
        <NavLink
          to={FACILITIES}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          FACILITIES
        </NavLink>
      </div>
      <div className="navbar-inner-container">
        <NavLink
          to={COMMITTEES}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          STATUTORY COMMITTEES
        </NavLink>
        <NavbarDropDown page={"Statutory"} />
      </div>
      <div className="navbar-inner-container">
        <NavLink
          to={DEPARTMENTS}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          DEPARTMENTS
        </NavLink>
        <NavbarDropDown page={"Departments"} />
      </div>
      <div className="navbar-inner-container">
        <NavLink
          to={PLACEMENTS}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          PLACEMENTS
        </NavLink>
        <NavbarDropDown page={"Placements"} />
      </div>
      <div className="navbar-inner-container">
        <NavLink
          to={STUDENTS}
          className="other-tabs"
          activeClassName="active-tabs"
        >
          STUDENTS' HUB
        </NavLink>
      </div>
      <div className="navbar-inner-container">
        <a
          href={nirfLink || OLD_WEBSITE + "/mec/news_and_events/nirf-2021.pdf"}
          target="_blank"
          rel="noopener noreferrer"
          className="other-tabs"
          activeClassName="active-tabs"
        >
          NIRF
        </a>
      </div>
    </div>
  );
};

export default Navbar;
