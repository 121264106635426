import React, { useState, useEffect } from "react";
import "./Sidebar.css";

interface SidebarProps {
  sidebarItems: SidebarItem[];
  selectedIdx: number;
}
export interface SidebarItem {
  onClick: () => any;
  name: string;
}
const Sidebar = ({ sidebarItems, selectedIdx }: SidebarProps) => {
  const [currentUrl, setCurrentUrl] = useState<string>(window.location.href);
  // console.log(window.location.href)
  useEffect(()=>{
    setCurrentUrl(window.location.href)
  },[currentUrl])
  return (
    <div className="page-nav">
      <ul className="sidebar-ul">
        {sidebarItems.map((item, i: number) =>
          !!item.name ? (
            i===3 && (currentUrl==='http://www.mec.ac.in/students/clubs' || currentUrl==='http://localhost:3000/students/clubs')?
            <li key={i}>
              <div
                className={`sidebar-nav-li ${
                  selectedIdx === i && "sidebar-nav-li-active"
                }`}
                onClick={item.onClick}
              >
                {item.name}
              </div>
              <div className="sidebar-spl"><a rel="noopener noreferrer" href='http://topgear.mec.ac.in/' target='_blank'>Top Gear</a></div>
            </li>
            :
            <li key={i}>
              <div
                className={`sidebar-nav-li ${
                  selectedIdx === i && "sidebar-nav-li-active"
                }`}
                onClick={item.onClick}
              >
                {item.name}
              </div>
            </li>
          ) : null
        )}
        
      </ul>
    </div>
  );
};

export default Sidebar;
