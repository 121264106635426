import React from "react";
import { MembersProps } from "../../pages/Committees/Committees";
import AdminMembers from "../common/Photo/AdminMembers";

const GrievanceCell = ({ members, title }: MembersProps) => {
  return (
    <div className="about">
      <h2 className="custom-h2">{title}</h2>
      <p className="custom-p">
        The grievance cell enquires and aids in the redressal of grievances and
        helps in the resolution of conflicts, in case any arise. It aims to
        provide a support system for students. Grievances at the department
        level are addressed by concerned teachers and class coordinators. The
        coordinators of clubs also act as facilitators to sort out complaints
        pertaining to various clubs and associations. A complaint box at college
        and a grievance redressal portal on the college website have also been
        made available. Students can also address their grievances directly to
        the principal.
      </p>
      <AdminMembers members={members} title={title} />
    </div>
  );
};

export default GrievanceCell;
