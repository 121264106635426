import React from "react";
import Admissions2k23card from "../../components/Admissions2024/Admissions2k23card";
import "./Admissionspage.css";
import { pgAdmissionDetails } from "./Admission2024";
import { ugAdmissionDetails } from "./Admission2024";
import { NavLink } from "react-router-dom";

const Admissionspage = () => {
  return (
    <div>
      {/* <div className='breadcrumb'>
        <div className='shadow'>
        </div>
          <h1 className='programmes'>Programmes Offered</h1>
      </div> */}
      <div className="admission">
        <h1>Admission Procedure</h1>
        <p>
          For B.Tech course, allotment to 95% (50% Merit- Regulated Fees and
          45%- Full Fees) seats of the annual intake is made by the Commissioner
          for Entrance Examination (CEE), Government of Kerala from the rank
          list of Kerala Engineering Entrance Examination conducted every year
          by Government of Kerala. 5% seats are reserved for non resident
          Indians (NRI seats). For NRI admission, separate applications are
          invited by Model Engineering College and rank list is prepared on the
          basis of qualifying examination and students are admitted from this
          rank list.
        </p>
      </div>
      <div className="admission2k23holder">
        <h2 className="ugprogrammes">UG programmes</h2>
        <div className="admissioncardholder">
          {ugAdmissionDetails.map((data) => (
            <Admissions2k23card
              key={data.name}
              name={data.name}
              seats={data.seats}
              trade="B.Tech"
              rank={data.rankmerit}
              new={data.new}
            />
          ))}
        </div>
        <div className="feestructure">
          <h2>Fee structure</h2>
          <table className="fee-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Merit Regulated</td>
                <td>₹22,500/Semester</td>
              </tr>
              <tr>
                <td>Merit Full fees</td>
                <td>₹37,500/Semester</td>
              </tr>
              <tr>
                <td>NRI - CS, CU, EC, EC(VLSI))</td>
                <td>₹2,00,000/Annum</td>
              </tr>
              <tr>
                <td>NRI - EEE, EB, ME</td>
                <td>₹1,50,000/Annum</td>
              </tr>
            </tbody>
          </table>
        </div>
        <section className="seatmatrix">
          <h2>Seat Matrix</h2>
          <div className="linegraph">
            <div className="merit-regulated">50%</div>
            <div className="merit-full-fees">45%</div>
            <div className="nri-fees">5%</div>
          </div>
          <div className="legends">
            <p className="merit-legend">Merit Regulated</p>
            <p className="manag-legend">Merit Full Fees</p>
            <p className="nri-legend">NRI</p>
          </div>
          <div className="extras">
            <NavLink to="/placements">
              <h3>Click here to see placement statistics</h3>
            </NavLink>
          </div>
        </section>
        <h2 className="ugprogrammes">PG programmes</h2>
        <div className="admissioncardholder">
          {pgAdmissionDetails.map((data) => (
            <Admissions2k23card
              key={data.name}
              name={data.name}
              seats={data.seats}
              trade="M.Tech"
              new={data.new}
              // rank={0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Admissionspage;
