import React, { useContext, useState, useEffect } from "react";
import { DocsContext } from "../../App";
import { OLD_WEBSITE } from "../../config/routes";

// import "./style.css";

const SafetyManual = () => {
  const docs = useContext(DocsContext);
  const [link, setLink] = useState("");

  useEffect(() => {
    const doc = docs.find((x) => x.title === "Safety Manual");
    if (doc) {
      setLink(doc.upload_file);
    }
  }, [docs]);

  return (
    <div className="about">
      <h2 className="custom-h2">Safety Manual</h2>
      <p className="custom-p">
        MEC is dedicated to providing a safe holistic learning environment for
        staffs and students alike. The safety manual has been composed to
        provide necessary information and procedures to follow to deal with
        accidents if they occur and also details precautions to be followed in
        labs and other places in the college.
      </p>
      <a
        className="custom-h3"
        href={link || OLD_WEBSITE + "/mec/other_documents/safety%20manual.pdf"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to download safety manual
      </a>
    </div>
  );
};

export default SafetyManual;
