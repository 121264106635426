import React from "react";

const AntiRaggingCommittee = () => {
  return (
    <div className="about">
      <h2 className="custom-h2">Anti-ragging Committee</h2>
      <p className="custom-p">
        An Anti-Ragging Committee has been set up to take action against ragging
        and resolve issues concerning the same. MEC, EKM- Anti-Ragging Committee
        formed vide this office order No.A3(2)/881/2014/MEC dated 22.07.2014-
        Members for the academic year 2019-20-intimating of -reg
      </p>
      <h4>Ref:-</h4>

      <ol className="custom-p">
        <li>
          Directions of the Hon'ble Supreme court dated 08.05.2009 to the state governments.
        </li>
        <li>Clause 5 of B.Tech Ordinance of KTU </li>
        <li>This office Order No.A3(2)/881/2014/MEC  dated 22.07.2014 </li>
      </ol>
      <p className="custom-p">
        As per references cited above, the constitution of antiragging committee was ordered in this institution.
        Accordingly the  committee members for the academic year 2021-22 are as follows.{" "}
      </p>
      <p className="custom-p">
      <strong>Chairman</strong>   -    Principal 
      </p>
      <p className="custom-p">
      <strong>Member Secretary</strong>   -    Dr.Sreenivas P ,Associate Prof.in Mechanical  (Chairman – Anti ragging squad.) 
      </p>
      {/* <h2 className="custom-h2">
        Accordingly the committee members for the academic year 2019-20 are as
        follows.
      </h2> */}
      <div className="list-image-container">{/* Images */}</div>
    </div>
  );
};

export default AntiRaggingCommittee;
