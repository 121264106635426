import React from "react";

import "./CompanyRegistration.css";

const CompanyRegistration = () => {
  return (
    <div className="company-registration">
      <h2 className="custom-h2">Company Registration</h2>
      <p className="custom-p">
        To go to company registration form,&nbsp;
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScaV1Ivg39rT0Y0gLdUIoZTCO9eTcPtYstAwU8MtTIaHO7-zg/viewform"
          className="show-form-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
        .
      </p>
      <iframe
        title="Company Registration Form"
        className="company-registration-form"
        src="https://docs.google.com/forms/d/e/1FAIpQLScaV1Ivg39rT0Y0gLdUIoZTCO9eTcPtYstAwU8MtTIaHO7-zg/viewform"
      />
    </div>
  );
};

export default CompanyRegistration;
