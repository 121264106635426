import React, { useState, useEffect } from "react";
import "./Photo.css";

import placeholder from "../../../assets/placeholder.jpg";
import LazyImage from "../LazyImage/LazyImage";

interface IPhotoProps {
  className?: string;
  name: string;
  position?: string;
  profile_link?: string;
  image: any;
  otherData?: {
    [key: string]: string | number;
  };
}

const Photo = ({
  name,
  position = "",
  image,
  otherData,
  profile_link,
  className,
}: IPhotoProps) => {
  const [data, setData] = useState<(string | number)[]>([]);
  useEffect(() => {
    otherData && setData(Object.values(otherData).filter((x) => x));
  }, [otherData]);
  return (
    <div className={`photo-item ${className}`}>
      <LazyImage src={image || placeholder} alt={`${name}'s photo`} />
      {!!profile_link ? (
        <a
          href={profile_link}
          className="custom-a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="person-name">{name}</p>
        </a>
      ) : (
        <p className="person-name">{name}</p>
      )}
      <p className="person-position">{position}</p>
      {!!data.length && data.map((x, i) => <p key={i}>{x}</p>)}
    </div>
  );
};

export default Photo;
