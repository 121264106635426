export const HOME = "/";
export const DEPARTMENTS = "/departments";
export const ADMISSIONS = "/admissions";
export const ADMISSIONS2024 = "/admissions2024";
export const FACILITIES = "/facilities";
export const COMMITTEES = "/committees";
export const ADMINISTRATIONS = "/administrations";
export const STUDENTS = "/students";
export const MISSION_VISION = "/mission-vision";
export const NOTIFICATIONS = "/notifications";
export const MAKE_A_DIFFERENCE = "/make-a-difference";
export const PLACEMENTS = "/placements";
export const INTERNSHIPS = PLACEMENTS + "/internships";
export const SYLLABUS = "/syllabus";
export const TENDERS = "/tenders";
export const DOWNLOADS = "/downloads";
export const MANDATORY = "/mandatory";
export const PG_BROCHURES = "/pg-brochures";
export const LIBRARY = "/library";
export const PLACEMENTSTATS = "/placements/placementstats";
export const CSE_DEPT = "/cse";
export const ECE_DEPT = "/ece";
export const EEE_DEPT = "/eee";
export const EBE_DEPT = "/ebe";
export const AS_DEPT = "/as";
export const ME_DEPT = "/me";

export const departmentRoutes = [
  CSE_DEPT,
  ECE_DEPT,
  EEE_DEPT,
  EBE_DEPT,
  AS_DEPT,
  ME_DEPT,
];

interface IDeptRoute {
  name: string;
  path: string;
}
export const deptRoutes: IDeptRoute[] = [
  { name: "Computer Engineering", path: DEPARTMENTS + CSE_DEPT },
  {
    name: "Electronics Engineering",
    path: DEPARTMENTS + ECE_DEPT,
  },
  {
    name: "Electrical & Electronics Engineering",
    path: DEPARTMENTS + EEE_DEPT,
  },
  {
    name: "Biomedical Engineering",
    path: DEPARTMENTS + EBE_DEPT,
  },
  { name: "Mechanical Engineering", path: DEPARTMENTS + ME_DEPT },
  { name: "Applied Science", path: DEPARTMENTS + AS_DEPT },
];

export const BTECH_CSE_ROUTE = CSE_DEPT + "/btech";
export const BTECH_ECE_ROUTE = ECE_DEPT + "/btech";
export const BTECH_EEE_ROUTE = EEE_DEPT + "/btech";
export const BTECH_EBE_ROUTE = EBE_DEPT + "/btech";
export const BTECH_ME_ROUTE = ME_DEPT + "/btech";
export const BTECH_CSBSE_ROUTE =
  "https://drive.google.com/file/d/1-LqlIh8bsaDuAnWu6om7yiEAaho6O07x/view";

export const BtechRoutes = [
  BTECH_CSE_ROUTE,
  BTECH_ECE_ROUTE,
  BTECH_EEE_ROUTE,
  BTECH_EBE_ROUTE,
  BTECH_ME_ROUTE,
  BTECH_CSBSE_ROUTE,
];

export const MTECH_CSE_ROUTE_IMGPROC = CSE_DEPT + "/mtech";
export const MTECH_ECE_ROUTE_VLSI = ECE_DEPT + "/mtech_vlsi";
export const MTECH_ECE_ROUTE_SIGPROC = ECE_DEPT + "/mtech_sig_proc";
export const MTECH_ECE_ROUTE_OPTO = ECE_DEPT + "/mtech_opto";
export const MTECH_ME_ROUTE = ME_DEPT + "/mtech";

export const MtechRoutes = [
  MTECH_CSE_ROUTE_IMGPROC,
  MTECH_ECE_ROUTE_VLSI,
  MTECH_ECE_ROUTE_SIGPROC,
  MTECH_ECE_ROUTE_OPTO,
  MTECH_ME_ROUTE,
];

export const STUD_ARTS = "/arts";
export const STUD_SPORTS = "/sports";
export const STUD_CLUBS = "/clubs";

export const OLD_WEBSITE = "https://www-mec.mec.ac.in";

export const ATTENDANCE = "http://attendance.mec.ac.in";
export const INTERNALS = "http://evaluation.mec.ac.in";
export const GRIEVANCE_REDRESSAL = "http://joy.mec.ac.in";
export const ONLINE_FACILITIES = "http://moodle.mec.ac.in";
export const EBOOKS = "https://ebookcentral.proquest.com/lib/mec-ebooks";
export const MEC_MAIL =
  "https://accounts.google.com/AddSession?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&service=mail&hd=mec.ac.in&sacu=1";
// export const Request_Form_For_Internal_Auditorium_Use =
//   "https://www.mec.ac.in/model_media/documents/Request_Form_for_Internal_Auditorium_use.pdf";
export const NSS = OLD_WEBSITE + "/nss/nssmec/";
export const IHRD = "http://ihrd.ac.in";
export const KTU = "https://ktu.edu.in";

export const FACEBOOK_URL = "https://www.facebook.com/modelengineeringcollege";
export const YOUTUBE_URL = "https://www.youtube.com/user/excelmec";
export const TWITTER_URL = "https://www.twitter.com/MECKochi";
export const LINKEDIN_URL =
  "https://in.linkedin.com/school/model-engineering-college/";
