import React, { lazy, Suspense, useState, useEffect } from "react";
import axios from "axios";

import { ApiRoot } from "../../config/api";

import "./Administrations.css";

import Sidebar from "../../components/common/Sidebar/Sidebar";
import MobileSidebar from "../../components/common/Sidebar/MobileSidebar";
import DottedLineLoader from "../../components/common/Loaders/DottedLineLoader";
import { IAdminMember } from "../../assets/interfaces";


const About = lazy(() => import("../../components/Administrations/About"));
const Principal = lazy(
  () => import("../../components/Administrations/Principal")
);
const Board = lazy(
  () => import("../../components/Administrations/Board-of-Governors")
);
const Staff = lazy(
  () => import("../../components/Administrations/Administrative-Staff")
);
const Council = lazy(
  () => import("../../components/Administrations/AcademicCouncil")
);
const PTA = lazy(() => import("../../components/Administrations/PTA"));
const Senate = lazy(() => import("../../components/Administrations/Senate"));

const filterName = "admin_page";
const filterValues = {
  boardOfGov: "Board of Governers",
  adminStaffs: "Administrative Staff",
  acadCouncil: "Academic Council",
  pta: "PTA",
  senate: "Senate",
};
const endpoint = `${ApiRoot}/administration/`;

export const administrationTabs = [
  "About",
  "Principal",
  "Board-of-Governers",
  "Administrative Staff",
  "Academic Council",
  "PTA",
  "Senate",
];
const Administrations = (props: any) => {
  const [showComponent, setShowComponent] = useState(
    props.location.section || 0
  );

  const [boardOfGov, setBoardOfGov] = useState<IAdminMember[]>([]);
  const [adminStaffs,setStaff] = useState<IAdminMember[]>([]);
  const [acadCouncil, setAcadCouncil] = useState<IAdminMember[]>([]);
  const [pta, setPta] = useState<IAdminMember[]>([]);
  const [senate, setSenate] = useState<IAdminMember[]>([]);

  useEffect(() => {
    setShowComponent(props.location.section || 0);
  }, [props.location.section]);

  useEffect(() => {
    axios
      .all([
        axios.get(`${endpoint}?${filterName}=${filterValues.boardOfGov}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.acadCouncil}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.pta}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.senate}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.adminStaffs}`),
      ])
      .then(
        axios.spread((boardOfGovRes, acadCouncilRes, ptaRes, senateRes, adminStaffsRes) => {
          setBoardOfGov(boardOfGovRes.data);
          setStaff(adminStaffsRes.data)
          setAcadCouncil(acadCouncilRes.data);
          setPta(ptaRes.data);
          setSenate(senateRes.data);
        })
      )
      .catch((errs) => console.log(errs));
  }, []);

  return (
    <div>
      <div className="page-title adm-head">
        <h1>Administration</h1>
      </div>

      <div className="page">
        <Sidebar
          sidebarItems={administrationTabs.map((tab, i) => ({
            name: tab,
            onClick: () => setShowComponent(i),
          }))}
          selectedIdx={showComponent}
        />

        <div className="page-content">
          <Suspense fallback={<DottedLineLoader />}>
            {showComponent === 0 && <About />}
            {showComponent === 1 && <Principal />}
            {showComponent === 2 && (
              <Board members={boardOfGov} title={filterValues.boardOfGov} />
            )}
            {showComponent === 3 && (
              <Staff members={adminStaffs} title={filterValues.adminStaffs} />
            )}
            {showComponent === 4 && (
              <Council members={acadCouncil} title={filterValues.acadCouncil} />
            )}
            {showComponent === 5 && (
              <PTA members={pta} title={filterValues.pta} />
            )}
            {showComponent === 6 && (
              <Senate members={senate} title={filterValues.senate} />
            )}
          </Suspense>
          <MobileSidebar
            sidebarItems={administrationTabs.map((tab, i) => ({
              name: tab,
              onClick: () => setShowComponent(i),
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default Administrations;
