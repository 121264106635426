import React from "react";
import { IDocument } from "../../assets/interfaces";
import { MembersProps } from "../../pages/Committees/Committees";
import CustomButton from "../common/CustomButton/CustomButton";
import AdminMembers from "../common/Photo/AdminMembers";

const objectives = [
  "To promote a social and psychological environment that will raise awareness about sexual harassment in its various forms. ",
  "To facilitate a safe environment that is free of sexual harassment. ",
  "To develop guidelines and norms for policies against sexual harassment. ",
  "To develop principles and procedures to combat sexual harassment. ",
  "To provide an environment free of gender-based discrimination. ",
  "To create a secure physical and social environment which will deter acts of sexual harassment",
];

const defn = [
  "Physical contact and advances. ",
  "Demand or request for sexual favours. ",
  "Sexually coloured remarks. ",
  "Any other unwelcome physical, verbal or non-verbal conduct of sexual nature. ",
  "Publishing, circulating or displaying pornographic, racist, sexually suggestive or otherwise offensive pictures or other materials. ",
];

interface AshProps extends MembersProps {
  handbook?: IDocument;
}

const AntiSexualHarassment = ({ members, title, handbook }: AshProps) => {
  return (
    <div className="about">
      <h2 className="custom-h2">{title}</h2>
      <p className="custom-p">
        As per the guidelines of UGC and the Supreme Court, an Anti-Sexual
        Harassment Cell and Internal Complaint Cell has been established by
        Model Engineering College to provide a healthy and congenial atmosphere
        to the staff and students of the College.
      </p>
      <p className="custom-p">
        The cell works to uphold zero tolerance towards sexual harassment. It
        promotes measures aimed at achieving gender equality, removal of gender
        bias or discrimination, sexual harassment, and other acts of
        gender-based violence by organising awareness programmes and campaigns
        for the benefit of all members of the college.
      </p>
      <h3 className="custom-h3">Objectives: </h3>
      <ul>
        {objectives.map((obj, i) => (
          <li key={i} className="custom-p">
            {obj}
          </li>
        ))}
      </ul>

      <h3 className="custom-h3">Definition of sexual harassment: </h3>
      <p className="custom-p">
        Sexual harassment includes such unwelcome sexually determined behaviour
        (whether directly or by implication) as:
      </p>
      <ul>
        {defn.map((obj, i) => (
          <li key={i} className="custom-p">
            {obj}
          </li>
        ))}
      </ul>
      <AdminMembers members={members} title={title} />

      <h3 className="custom-h3">On receipt of complaint: </h3>
      <p className="custom-p">
        Anti-Sexual Harassment Cell assures that all the complaints of the
        students, teaching and non- teaching staffs are treated with dignity,
        respect and the complaints shall be maintained confidential.
      </p>

      <h3 className="custom-h3">False Reporting: </h3>
      <p className="custom-p">
        Intentionally making a false report or providing false information is
        grounds for indiscipline. {"\n\n"}Handbook on sexual harassment of women
        at workplace by Ministry of women and child development, 2015{" "}
        <a
          href={handbook ? handbook.upload_file : ""}
          target="_blank"
          rel="noopener noreferrer"
          className="custom-a"
        >
          (click here)
        </a>
        .
      </p>
      <a
        href="https://forms.gle/AH61YyrifG6mBTELA"
        target="_blank"
        rel="noopener noreferrer"
        className="custom-a flex justify-center"
      >
        <CustomButton text={"Log Complaint"} action={() => { }} />
      </a>
    </div>
  );
};

export default AntiSexualHarassment;
