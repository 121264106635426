import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PlacementStats from "./components/Placements/PlacementSidebarPages/PlacementStats";
import "./App.css";
import "./pages/CommonPage.css";

import axios from "axios";
import Loader from "./components/common/Loaders/Loader";
import Navbar from "./components/common/Navbar/Navbar";
import Breadcrumb from "./components/common/Breadcrumb/Breadcrumb";
import Admissionspage from "./pages/Admission2024/Admissionspage";
import {
  HOME,
  DEPARTMENTS,
  ADMISSIONS,
  COMMITTEES,
  ADMINISTRATIONS,
  STUDENTS,
  FACILITIES,
  MISSION_VISION,
  NOTIFICATIONS,
  PLACEMENTS,
  MAKE_A_DIFFERENCE,
  LIBRARY,
  TENDERS,
  MANDATORY,
  SYLLABUS,
  INTERNSHIPS,
  PG_BROCHURES,
  PLACEMENTSTATS,
  ADMISSIONS2024,
  DOWNLOADS,
} from "./config/routes";
import { IDocument } from "./assets/interfaces";
import { ApiRoot } from "./config/api";

const Home = lazy(() => import("./pages/Home/Home"));
const Footer = lazy(() => import("./components/Home/Footer"));
const Departments = lazy(() => import("./pages/Departments/Departments"));
const Admissions = lazy(() => import("./pages/Admissions/Admissions"));
const Committees = lazy(() => import("./pages/Committees/Committees"));
const Students = lazy(() => import("./pages/Students/Students"));
const Placements = lazy(() => import("./pages/Placements/Placement"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const MissionVision = lazy(
  () => import("./pages/Mission-Vision/Mission-Vision")
);
const Administrations = lazy(
  () => import("./pages/Administrations/Administrations")
);
const Difference = lazy(
  () => import("./pages/MakeADifference/MakeADifference")
);
const Library = lazy(() => import("./pages/Library/Library"));
const FacilitiesPage = lazy(
  () => import("./pages/FacilitiesPage/FacilitiesPage")
);
const Tenders = lazy(() => import("./pages/Tenders/Tenders"));
const Downloads = lazy(() => import("./pages/Downloads/Downloads"));
const Mandatory = lazy(() => import("./pages/Mandatory/Mandatory"));
const PGBrochures = lazy(() => import("./pages/PGBrochures/PGBrochures"));
const Syllabus = lazy(() => import("./pages/Syllabus/Syllabus"));

// const GrievanceRedressal = lazy(() =>
//   import("./pages/GrievanceRedressal/GrievanceRedressal")
// );
// const Attendance = lazy(() => import("./components/Students/Attendance"));
// const Intassess = lazy(() => import("./components/Students/Intassess"));
const NotificationsPage = lazy(
  () => import("./pages/NotificationsPage/NotificationsPage")
);
const Internship = lazy(() => import("./pages/Placements/Internship"));

export const DocsContext = React.createContext<IDocument[]>([]);

export const App = () => {
  const [docs, setDocs] = useState<IDocument[]>([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    axios
      .get(`${ApiRoot}/documents?section=CommonDocuments`)
      .then((res) => {
        // console.log(res.data);
        setDocs(res.data as IDocument[]);
      })
      .catch((err) => console.log(err));
    // .finally(() => setLoading(false));
  }, []);
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <DocsContext.Provider value={docs}>
          <Navbar />
          <Breadcrumb />
          <Switch>
            <Route exact path={HOME} component={Home} />
            <Route path={DEPARTMENTS} component={Departments} />
            <Route path={ADMISSIONS} component={Admissions} />
            <Route path={COMMITTEES} component={Committees} />
            <Route path={ADMINISTRATIONS} component={Administrations} />
            <Route path={STUDENTS} component={Students} />
            {/* <Route
            exact
            path="/grievance-redressal"
            component={GrievanceRedressal}
          /> */}
            {/* <Route path="/attendance" component={Attendance} />
          <Route path="/internals" component={Intassess} /> */}
            <Route path={LIBRARY} component={Library} />
            <Route path={FACILITIES} component={FacilitiesPage} />
            <Route path={TENDERS} component={Tenders} />
            <Route path={DOWNLOADS} component={Downloads} />
            <Route path={MANDATORY} component={Mandatory} />
            <Route path={PG_BROCHURES} component={PGBrochures} />
            <Route path={MISSION_VISION} component={MissionVision} />
            <Route path={NOTIFICATIONS} component={NotificationsPage} />
            <Route exact path={PLACEMENTS} component={Placements} />
            <Route path={INTERNSHIPS} component={Internship} />
            <Route path={PLACEMENTSTATS} component={PlacementStats} />
            <Route path={MAKE_A_DIFFERENCE} component={Difference} />
            <Route path={SYLLABUS} component={Syllabus} />
            <Route path={ADMISSIONS2024} component={Admissionspage} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </DocsContext.Provider>
      </Suspense>
    </Router>
  );
};

export default App;
