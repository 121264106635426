import React from "react";

const About = () => {
  return (
    <div className="about">
      <h2 className="custom-h2">About</h2>
      <p className="custom-p">
        Statutory Committees have been established for the purpose of swiftly
        and efficiently carrying out administrative tasks, to address issues of
        concern and to ensure an overall great college atmosphere. A number of
        statutory bodies have been constituted, with varying aims. The members
        in these committees are appointed for fixed terms depending on the type
        of committee.
      </p>
    </div>
  );
};

export default About;
