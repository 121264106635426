import React, { useState,useEffect } from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import dropDownIcon from "../../../assets/Home/menu-arrow.svg";
import LazyImage from "../LazyImage/LazyImage";
import { SidebarItem } from "./Sidebar";

import "./MobileSidebar.css";

interface SidebarProps {
  sidebarItems: SidebarItem[];
}

const MobileSidebar = ({ sidebarItems }: SidebarProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowDimensions();
  const [currentUrl, setCurrentUrl] = useState<string>(window.location.href);
  // console.log(window.location.href)
  useEffect(()=>{
    setCurrentUrl(window.location.href)
  },[currentUrl])
  return width > 768 ? null : (
    <div className="mobileSidebar-container">
      {isModalOpen ? (
        <LazyImage
          className="mobile-sidebar-arrow"
          src={dropDownIcon}
          alt="down-arrow"
          style={{ transform: "rotateX(180deg)" }}
          onClick={() => setIsModalOpen(false)}
        />
      ) : (
        <LazyImage
          className="mobile-sidebar-arrow"
          alt="down-arrow"
          src={dropDownIcon}
          onClick={() => setIsModalOpen(true)}
        />
      )}
      {isModalOpen && (
        <div className="mobileSidebar-nav-container">
          {sidebarItems.map((item, i) => (
          i===3 && (currentUrl==='http://www.mec.ac.in/students/clubs' || currentUrl==='http://localhost:3000/students/clubs')?<>
            <div
              key={i}
              className="mobileSidebar-nav pointer"
              onClick={() => {
                setIsModalOpen(false);
                item.onClick();
              }}
            >
              {item.name}
            </div>
            <div className="mobileSidebar-nav pointer spl"><a href='http://topgear.mec.ac.in/' rel="noopener noreferrer" target='_blank'>Top Gear</a></div>
            </> : 
                <div
                key={i}
                className="mobileSidebar-nav pointer"
                onClick={() => {
                  setIsModalOpen(false);
                  item.onClick();
                }}
              >
                {item.name}
              </div>
          ))}
          
        </div>
      )}{" "}
    </div>
  );
};

export default MobileSidebar;
