import React, { useState, useEffect } from "react";
import axios from "axios";

import { ApiRoot } from "../../../config/api";

import LazyImage from "../../common/LazyImage/LazyImage";

import "./Brochure.css";
import { CarouselData, IDocument } from "../../../assets/interfaces";
import DottedLineLoader from "../../common/Loaders/DottedLineLoader";

const Brochure = () => {
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [brochures, setBrochures] = useState<IDocument[]>([]);
  const [previews, setPreviews] = useState<CarouselData[]>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiRoot}/documents?section=PlacementBrochure`)
      .then((res) => {
        const data = res.data as IDocument[];
        setBrochures(data.sort((a, b) => (a.title < b.title ? 1 : -1)));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setPreviewLoading(true);
    axios
      .get(`${ApiRoot}/carousels?section=PlacementBrochure`)
      .then((res) => {
        const data = res.data as CarouselData[];
        setPreviews(data.sort((a, b) => (a.title < b.title ? 1 : -1)));
      })
      .catch((err) => console.log(err))
      .finally(() => setPreviewLoading(false));
  }, []);

  return (
    <div className="brochure">
      <h2 className="custom-h2">Brochure</h2>
      <div className="brochure-grid">
        {loading || previewLoading ? (
          <DottedLineLoader />
        ) : brochures.length !== 0 ? (
          brochures.map((brochure, i) => (
            <a href={brochure.upload_file} key={i} target='_blank' rel="noopener noreferrer">
              <LazyImage src={previews[i].image} alt={brochure.title} />
            </a>
          ))
        ) : (
          <p className="custom-p">No brochures to display</p>
        )}
      </div>
    </div>
  );
};

export default Brochure;
