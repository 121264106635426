import React from "react";
import { MembersProps } from "../../../pages/Committees/Committees";
import Photo from "./Photo";

const AdminMembers = ({ members, title }: MembersProps) => {
  if (members.length)
    return (
      <>
        <h3 className="custom-h3">Members of the {title}</h3>
        <div className="list-image-container">
          {members.map(
            (
              { name, position, image, description, email_id, contact_no },
              i
            ) => (
              <Photo
                key={i}
                name={name}
                position={position}
                image={image}
                otherData={{
                  description,
                  email_id: email_id || "",
                  contact_no: !!contact_no ? contact_no : "",
                }}
              />
            )
          )}
        </div>
      </>
    );
  return null;
};

export default AdminMembers;
