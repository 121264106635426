import React from "react";

const TrainingCell = () => {
  return (
    <div className="">
      <h2 className="custom-h2">Training Cell</h2>
      <p className="custom-p">
        The training cell is a professional body of the college run by the
        skilled students of the college, under the expert guidance of the staff.
        This body facilitates the process of placement of students passing out
        from the Institute besides collaborating with leading organizations and
        institutes in the setting up of training programs for the students of
        this esteemed college. The Training Cell's aim is to create an
        environment of technical and soft skill learning, to develop them in
        accordance with the current Industry standards.{" "}
      </p>
    </div>
  );
};

export default TrainingCell;
