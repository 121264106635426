export const ugAdmissionDetails = [
    {
        name: "Computer Science and Engineering",
        seats: 180,
        rankmerit: 1740,
        rankmanagment: 3811,
        new: false
    },
    {
        name: "Electronics and Communication",
        seats: 120,
        rankmerit: 4045,
        rankmanagment: 7336,
        new: false
    },
    {
        name: "Computer Science and Business Systems",
        seats: 60,
        rankmerit: 4460,
        rankmanagment: 9557,
        new: false
    },
    {
        name: "Electrical and Electronics",
        seats: 60,
        rankmerit: 8962,
        rankmanagment: 16322,
        new: false
    },
    {
        name: "Electronics and Biomedical",
        seats: 60,
        rankmerit: 15360,
        rankmanagment: 47684,
        new: false
    },
    {
        name: "Mechanical Engineering",
        seats: 60,
        rankmerit: 22507,
        rankmanagment: 46365,
        new: false
    },
    {
        name: "Electronics(VLSI design and technology) ",
        seats: 60,
        rankmerit: 14004,
        rankmanagment: 39466,
        new: false
    },
    {
        name: "Electrical and Electronics Engineering for Working Professionals",
        seats: 30,
        new: true
    },
    {
        name: "Electronics and Communication Engineering for Working Professionals",
        seats: 30,
        new: true
    },
];

export const pgAdmissionDetails = [
    {
        name: "VLSI design and Embedded",
        seats: 24,
        new: false
    },
    // {
    //     name: "Electronics, Specialization in Signal Processing",
    //     seats: 18,
    //     new: false
    // },
    // {
    //   name: "M.Tech ECE_Opto",
    //   seats: 18,
    //   desc: [
    //     "M.Tech",
    //     "Electronics",
    //     "Optoelectronics and Communication Systems",
    //   ],
    //   link: MTECH_ECE_ROUTE_OPTO,
    //   detail:
    //     "This course in Optoelectronics focuses on giving a clear cut knowledge in subjects like Modern Optics, Optical Signal Processing, Digital Communication Techniques, Laser Technology, Biophotonics and Optical Communication.",
    // },
    {
        name: "Mechanical, Specialization in Energy Management",
        seats: 18,
        new: false
    },
    {
        name: "Computer Science, Specialization in Data Science ",
        seats: 18,
        new: false
    }
];