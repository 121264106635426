import React from "react";
import "./DottedLineLoader.css";

const DottedLineLoader = () => {
  return (
    <div className="dotted-line-spinner" style={{ margin: "auto" }}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};

export default DottedLineLoader;
