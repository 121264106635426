import React, { useState, useEffect } from "react";

import "./PlacementSidebar.css";

import PlacementStats from "./PlacementSidebarPages/PlacementStats";
import Activities from "./PlacementSidebarPages/Activities";
import Brochure from "./PlacementSidebarPages/Brochure";
import OurReach from "./PlacementSidebarPages/OurReach";
import StudentVerification from "./PlacementSidebarPages/StudentVerification";
import CompanyRegistration from "./PlacementSidebarPages/CompanyRegistration";
import ContactDetails from "./PlacementSidebarPages/ContactDetails";
import TrainingCell from "./PlacementSidebarPages/TrainingCell";

import Sidebar from "../common/Sidebar/Sidebar";
import MobileSidebar from "../common/Sidebar/MobileSidebar";
import { useLocation } from "react-router-dom";

export const placementSidebarTabs = [
  "Activities",
  "Placement Statistics",
  "Brochure",
  "Our Reach",
  "Student Verification",
  "Company Registration",
  "Contact Details",
  "Training Cell",
];

const PlacementSidebar = () => {
  const location: any = useLocation();
  const [showComponent, setShowComponent] = useState(location.section || 0); // default 0 for "About"

  useEffect(() => {
    if (location.section) {
      setShowComponent(location.section);
      const element = document.getElementById("placement-sidebar-holder");
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0,
      });
    }
  }, [location]);

  return (
    <div className="placement-sidebar">
      <Sidebar
        sidebarItems={placementSidebarTabs.map((tab, i) => ({
          name: tab,
          onClick: () => setShowComponent(i),
        }))}
        selectedIdx={showComponent}
      />
      <div className="page-content" id="placement-sidebar-holder">
        {showComponent === 0 && <Activities />}
        {showComponent === 1 && <PlacementStats />}
        {showComponent === 2 && <Brochure />}
        {showComponent === 3 && <OurReach />}
        {showComponent === 4 && <StudentVerification />}
        {showComponent === 5 && <CompanyRegistration />}
        {showComponent === 6 && <ContactDetails />}
        {showComponent === 7 && <TrainingCell />}
        <MobileSidebar
          sidebarItems={placementSidebarTabs.map((tab, i) => ({
            name: tab,
            onClick: () => setShowComponent(i),
          }))}
        />
      </div>
    </div>
  );
};

export default PlacementSidebar;
