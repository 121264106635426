import {
  ADMINISTRATIONS,
  ADMISSIONS,
  COMMITTEES,
  DEPARTMENTS,
  deptRoutes,
  FACILITIES,
  HOME,
  PLACEMENTS,
  STUDENTS,
  STUD_ARTS,
  STUD_CLUBS,
  STUD_SPORTS,
} from "../../../config/routes";
import { administrationTabs } from "../../../pages/Administrations/Administrations";
import { committeesTabs } from "../../../pages/Committees/Committees";
import { placementSidebarTabs } from "../../Placements/PlacementSidebar";

interface NavbarItem {
  link: string | undefined;
  name: string;
  children: NavbarItem[] | SectionItem[] | null;
}

interface SectionItem {
  link: string;
  name: string;
  section: number;
}

const navbarLinks: NavbarItem[] = [
  {
    link: HOME,
    name: "Home",
    children: null,
  },
  {
    link: ADMINISTRATIONS,
    name: "Administration",
    children: administrationTabs.map((tab, i) => ({
      link: ADMINISTRATIONS,
      name: tab,
      section: i,
    })),
  },
  {
    link: undefined,
    name: "Admissions",
    children: [
    {
      link: "admissions2024",
      name: "Admission 2024",
      section: 2
    },{
        link: ADMISSIONS,
        name: "Admissions",
        section: 1
    }],
  },
  {
    link: FACILITIES,
    name: "Facilities",
    children: null,
  },
  {
    link: COMMITTEES,
    name: "Statutory Committees",
    children: committeesTabs.map((tab, i) => ({
      link: COMMITTEES,
      name: tab,
      section: i,
    })),
  },
  {
    link: DEPARTMENTS,
    name: "Departments",
    children: deptRoutes.map((route) => ({
      link: route.path,
      name: route.name,
      children: null,
    })),
  },
  {
    link: PLACEMENTS,
    name: "Placements",
    children: placementSidebarTabs.map((tab, i) => ({
      link: PLACEMENTS,
      name: tab,
      section: i,
    })),
  },
  {
    link: STUDENTS,
    name: "Students' Corner",
    children: [
      {
        link: STUDENTS + STUD_ARTS,
        name: "Arts",
        children: null,
      },
      {
        link: STUDENTS + STUD_SPORTS,
        name: "Sports",
        children: null,
      },
      {
        link: STUDENTS + STUD_CLUBS,
        name: "Clubs",
        children: null,
      },
    ],
  },
];

export default navbarLinks;
