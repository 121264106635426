import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import "./Committees.css";

import About from "../../components/Committees/About";
import InternalQualityAssuranceCell from "../../components/Committees/InternalQualityAssuranceCell";
import GrievanceCell from "../../components/Committees/GrievanceCell";
import AntiRaggingCommittee from "../../components/Committees/AntiRaggingCommittee";
import AntiRaggingSquad from "../../components/Committees/AntiRaggingSquad";
import AntiSexualHarassment from "../../components/Committees/AntiSexualHarassment";
import SafetyManual from "../../components/Committees/SafetyManual";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import MobileSidebar from "../../components/common/Sidebar/MobileSidebar";
import { IAdminMember, IDocument } from "../../assets/interfaces";
import { ApiRoot } from "../../config/api";

export const committeesTabs = [
  "About",
  "Internal Quality Assurance Cell",
  "Grievance Cell",
  "Anti-ragging Committee",
  "Anti-ragging Squad",
  "Anti-sexual Harassment & Internal Compliance Cell",
  "Safety Manual",
];

export interface MembersProps {
  title: string;
  members: IAdminMember[];
}

const filterName = "admin_page";
const filterValues = {
  iqaCell: "Internal QA Cell",
  grievanceCell: "Grievance Cell",
  antiRaggingSquad: "Anti-Ragging Squad",
  ahicCommittee: "Anti-sexual Harassment and Internal Compliance Committee",
};
const endpoint = `${ApiRoot}/administration/`;

const ComputerScience = (props: any) => {
  const [showComponent, setShowComponent] = useState(
    props.location.section || 0
  ); // default 0 for "About"

  const [iqaCell, setIqaCell] = useState<IAdminMember[]>([]);
  const [grievanceCell, setGrievanceCell] = useState<IAdminMember[]>([]);
  const [antiRaggingSquad, setAntiRaggingSquad] = useState<IAdminMember[]>([]);
  const [ahicCommittee, setAhicCommittee] = useState<IAdminMember[]>([]);

  const [docs, setDocs] = useState<IDocument[]>([]);

  useEffect(() => {
    // setLoading(true);
    axios
      .get(`${ApiRoot}/documents?section=stat_committees`)
      .then((res) => {
        // console.log(res.data);
        setDocs(res.data as IDocument[]);
      })
      .catch((err) => console.log(err));
    // .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setShowComponent(props.location.section || 0);
  }, [props.location.section]);

  useEffect(() => {
    axios
      .all([
        axios.get(`${endpoint}?${filterName}=${filterValues.iqaCell}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.grievanceCell}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.antiRaggingSquad}`),
        axios.get(`${endpoint}?${filterName}=${filterValues.ahicCommittee}`),
      ])
      .then(
        axios.spread(
          (
            iqaCellRes,
            grievanceCellRes,
            antiRaggingSquadRes,
            ahicCommitteeRes
          ) => {
            setIqaCell(iqaCellRes.data);
            setGrievanceCell(grievanceCellRes.data);
            setAntiRaggingSquad(antiRaggingSquadRes.data);
            setAhicCommittee(ahicCommitteeRes.data);
          }
        )
      )
      .catch((errs) => console.log(errs));
  }, []);

  const getComponent = useCallback(() => {
    switch (showComponent) {
      case 0:
        return <About />;
      case 1:
        return (
          <InternalQualityAssuranceCell
            members={iqaCell}
            title={"Internal Quality Assurance Cell"}
          />
        );
      case 2:
        return (
          <GrievanceCell
            members={grievanceCell}
            title={filterValues.grievanceCell}
          />
        );
      case 3:
        return <AntiRaggingCommittee />;
      case 4:
        return (
          <AntiRaggingSquad
            members={antiRaggingSquad}
            title={filterValues.antiRaggingSquad}
          />
        );
      case 5:
        return (
          <AntiSexualHarassment
            members={ahicCommittee}
            title={filterValues.ahicCommittee}
            handbook={docs.find((doc) => doc.title === "icc_handbook")}
          />
        );
      case 6:
        return <SafetyManual />;
    }
  }, [
    showComponent,
    iqaCell,
    grievanceCell,
    antiRaggingSquad,
    ahicCommittee,
    docs,
  ]);

  return (
    <div>
      <div className="page-title committee-title">
        <h1>Statutory Committees</h1>
      </div>
      <div className="page">
        <Sidebar
          sidebarItems={committeesTabs.map((tab, i) => ({
            name: tab,
            onClick: () => setShowComponent(i),
          }))}
          selectedIdx={showComponent}
        />
        <div className="page-content">
          {getComponent()}
          <MobileSidebar
            sidebarItems={committeesTabs.map((tab, i) => ({
              name: tab,
              onClick: () => setShowComponent(i),
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default ComputerScience;
