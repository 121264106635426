// import { color } from "@mui/system";
import React, { useState, useEffect } from "react";
import "./Table.css";
import axios from "axios";
import { ApiRoot } from "../../../config/api";

interface Company {
  logo: string;
  name: string;
}

interface PlacementData {
  company: Company;
  no_of_offers: {
    CSE: number;
    EBE: number;
    ECE: number;
    EEE: number;
    ME: number;
  };
  name: string;
}

const Table: React.FC = () => {
  const [data, setData] = useState<PlacementData[]>([]);
  const [year, setYear] = useState<string>("2023"); // default year is 2022
  const [key, setKey] = useState<Boolean>(false);

  useEffect(() => {
    responseFetch()
  }, [year]); // re-fetch data whenever year changes
async function responseFetch(){
  const response=await axios.get(`${ApiRoot}/placement/?year=${year}&type_placement=placement`)
  setData(response.data)
  if (year!=='2007'){
    setKey(response.data[0].no_of_offers.ME)
  }
  }
 
  const getColumnData = (columnName: keyof PlacementData["no_of_offers"]) => {
    return data.map((placement) =>
      placement.no_of_offers && placement.no_of_offers[columnName]
    );
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(event.target.value);
  };

  return (
    <div className="parent">
      <select value={year} onChange={handleYearChange} className=" select pointer">
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option>
        <option value="2016">2016</option>
        <option value="2015">2015</option>
        <option value="2014">2014</option>
        <option value="2013">2013</option>
        <option value="2012">2012</option>
        <option value="2011">2011</option>
        <option value="2010">2010</option>
        <option value="2009">2009</option>
        <option value="2008">2008</option>
        <option value="2007">2007</option>
        <option value="2006">2006</option>
        <option value="2005">2005</option>
      </select>
      <table className="Table" style={{ marginBottom: "20px" }}>
        <thead>
          <tr>
            {/* <th style={{ color: "white" }}>Logo</th> */}
            <th style={{ color: "white" }}>Company</th>
            <th style={{ color: "white" }}>CSE</th>
            <th style={{ color: "white" }}>EBE</th>
            <th style={{ color: "white" }}>ECE</th>
            <th style={{ color: "white" }}>EEE</th>
            {key ? <th style={{ color: "white" }}>ME</th> : null}
            <th style={{ color: "white" }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((placement) => (
            <tr key={placement.name}>
              {/* <td>
                <img
                  src={placement.company.logo}
                  width="70px"
                alt={`${placement.name} Logo`}
              />
            </td> */}
            <td className="company">
            <div className="company_element">
            <div style={{width:'30%'}} className="logoImage">
            <img
                  src={placement.company.logo}
                  width="70px"
                alt={`${placement.name} Logo`}
              />
            </div>
              <div className="company_name">{placement.company.name[0].toUpperCase() +
                placement.company.name.slice(1)}</div>
            </div>
            </td>
            <td>{placement.no_of_offers && placement.no_of_offers.CSE}</td>
            <td>{placement.no_of_offers && placement.no_of_offers.EBE}</td>
            <td>{placement.no_of_offers && placement.no_of_offers.ECE}</td>
            <td>{placement.no_of_offers && placement.no_of_offers.EEE}</td>
            {key ? <td>{placement.no_of_offers && placement.no_of_offers.ME}</td> : null}
            {key ? null : <td>
              {placement.no_of_offers &&
                placement.no_of_offers.CSE +
                  placement.no_of_offers.EBE +
                  placement.no_of_offers.ECE +
                  placement.no_of_offers.EEE}
            </td>}
            {key? <td>
              {placement.no_of_offers &&
                placement.no_of_offers.CSE +
                  placement.no_of_offers.EBE +
                  placement.no_of_offers.ECE +
                  placement.no_of_offers.EEE + 
                  placement.no_of_offers.ME}
            </td>:null}
          </tr>
        ))}
        <tr style={{backgroundColor:'#DADADA', height:'50px'}} >        
          <td style={{color:"red"}}>TOTAL </td>
          <td>{getColumnData("CSE").reduce((a, b) => a + b, 0)}</td>
          <td>{getColumnData("EBE").reduce((a, b) => a + b, 0)}</td>
          <td>{getColumnData("ECE").reduce((a, b) => a + b, 0)}</td>
          <td>{getColumnData("EEE").reduce((a, b) => a + b, 0)}</td>
          {key ? <td>{getColumnData("ME").reduce((a, b) => a + b, 0)}</td>: null}
          {key ? null : <td>{getColumnData("CSE").reduce((a, b) => a + b, 0)+getColumnData("EBE").reduce((a, b) => a + b, 0)+getColumnData("ECE").reduce((a, b) => a + b, 0)+getColumnData("EEE").reduce((a, b) => a + b, 0)}</td>}
          {key ? <td>{getColumnData("CSE").reduce((a, b) => a + b, 0)+getColumnData("EBE").reduce((a, b) => a + b, 0)+getColumnData("ECE").reduce((a, b) => a + b, 0)+getColumnData("EEE").reduce((a, b) => a + b, 0)+getColumnData("ME").reduce((a, b) => a + b, 0)}</td>: null}
        </tr>
      </tbody>
    </table>
    {/* <div>{data[0]? data[0].no_of_offers.CSE.toString():null}</div> */}
    <div>{key}</div>
    </div>
  );
};

export default Table;
