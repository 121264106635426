import React, {useContext, useState, useEffect} from "react";
import { DocsContext } from "../../../App";
import { OLD_WEBSITE } from "../../../config/routes";

import "./StudentVerification.css";

const StudentVerification = () => {
  const docs = useContext(DocsContext);
  const [link, setLink] = useState("");

  useEffect(() => {
    const doc = docs.find((x) => x.title === "Certificate Verification");
    if (doc) {
      setLink(doc.upload_file);
    }
  }, [docs]);

  return (
    <div className="student-verification">
      <h2 className="custom-h2">Student Verification</h2>
      <p className="custom-p">
        Authorized verifying agencies / Organizations who would like to get the
        academic records of students graduated from Govt. Model Engineering
        College verified for Employment purposes are expected to submit a formal
        request in the official stationary of the authorized verifying agency
        addressed to the Principal, Govt. Model Engineering Collge, Thrikkakara
        P.O., Ernakulam, Kochi, Kerala-682021 with the following:
      </p>
      <ul>
        <li>
          Details of the student which are to be verified at institution level.
        </li>
        <li>
          Attach a DD for Rs: 500/ in favour of Principal, Govt. Model
          Engineering College payable at Ernakulam.
        </li>
        <li>
          Give the preferred communication address for conveying the required
          information, viz. Fax number, E-mail address or Postal address with
          pin
        </li>
      </ul>
      <p className="custom-p">
        Sd/-<br></br>
        PRINCIPAL
      </p>
      <a
        className="custom-h3"
        href={link || OLD_WEBSITE + "/mec/pdf/certificate-verification.pdf"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to download request form
      </a>
    </div>
  );
};

export default StudentVerification;
