import React from "react";

import "./Activities.css";

const Activities = () => {
  return (
    <div className="activities">
      <h2 className="custom-h2">Activities</h2>
      <ul>
        <li>
          We have been conducting session after session of grueling tests, mock
          interviews and group discussions.
        </li>
        <li>Online Technical tests are regularly conducted.</li>
        <li>
          Soft skill development programs required for the corporates are also
          part of our curriculum.
        </li>
        <li>
          We have been holding talks by eminent personalities from different
          walks of life, on career guidance, importance of communication skills
          and on other related stuff.
        </li>
        <li>
          Faculty from Management Training Institutes hold regular classes on
          personality development.
        </li>
        <li>
          XMECians hold regular talks on the current industry trends and job
          opportunities in India and abroad.
        </li>
        <li>
          Also purely technical talks are conducted with a view of the expansion
          of technical knowledge, which is much sought after in the industry.
        </li>
      </ul>
    </div>
  );
};

export default Activities;
