import React from "react";
import { MembersProps } from "../../pages/Committees/Committees";
import AdminMembers from "../common/Photo/AdminMembers";

const AntiRaggingSquad = ({ members, title }: MembersProps) => {
  return (
    <div className="about">
      <h2 className="custom-h2">{title}</h2>
      <p className="custom-p">
        The anti-ragging squad has been formed to help create and foster a safe
        and welcoming college community. It helps maintain the strict no
        tolerance attitude taken towards ragging in the college campus. It
        conducts frequent visits and surprises raids to student hostels and
        other places in the college campus where potential ragging may occur.
        The anti-ragging squad is expected to submit regular periodical reports
        on their activities and any case of detected student harassment is
        reported to the principal immediately.
      </p>
      <h4>Ref:-</h4>

      <ol className="custom-p">
        <li>
          Directions of the Hon'ble Supreme court dated 08.05.2009 to the state
          governments.
        </li>
        <li>Clause 5 of B.Tech Ordinance of KTU. </li>
        <li>This office Order No.A3(2)/881/2014/MEC dated 22.07.2014</li>
      </ol>
      <p className="custom-p">
        As per references cited above, the constitution of antiragging committee
        was ordered in this institution.
      </p>
      <AdminMembers members={members} title={title} />
    </div>
  );
};

export default AntiRaggingSquad;
