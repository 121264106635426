import React from "react";
import "./CustomButton.css";

interface IProps {
  text: string;
  action: () => any;
}

const CustomButton = ({ text, action }: IProps) => {
  return (
    <div onClick={action} className="buttonDiv">
      {text}
    </div>
  );
};

export default CustomButton;
