import React from "react";

import "./ContactDetails.css";
import LazyImage from "../../common/LazyImage/LazyImage";

import mail from "../../../assets/Placement/mail.png";
import phone from "../../../assets/Placement/phone.png";

interface IContact {
  name: string;
  position?: string;
  phNum: string;
  emailId: string;
}
const Contact = ({ name, position, phNum, emailId }: IContact) => (
  <p className="custom-p">
    <span>
      {name}
      <br />
    </span>
    {position && (
      <span>
        {position}
        <br />
      </span>
    )}
    <span>
      <LazyImage src={mail} alt="Mail" width={18} /> &nbsp;{emailId}
    </span>
    <br />
    <span>
      <LazyImage src={phone} alt="Phone" width={18} /> &nbsp;{phNum}
      <br></br>
    </span>
  </p>
);

const ContactDetails = () => {
  return (
    <div className="contact-details">
      <h2 className="custom-h2">Address for Correspondence</h2>
      <ul>
        <li>The Principal</li>
        <li>Model Engineering College</li>
        <li>Thrikkakara, Kochi,</li>
        <li>Kerala</li>
        <li>PIN: 682021</li>
        <li className="flex align-center">
          <LazyImage src={phone} alt="Phone" width={18} height={15} />
          &nbsp;+91 484 2575592
        </li>
        <li className="flex align-center">
          <LazyImage src={mail} alt="Mail" width={18} height={13.5} />
          &nbsp;principal@mec.ac.in
        </li>
      </ul>

      <h2 className="custom-h2">Placement Committee</h2>
      <div className="placement-comittee-grid">
        <div className="placement-comittee-grid-left">
          <h3>Chairman</h3>
        </div>
        <div className="placement-comittee-grid-right">
          <p className="custom-p">
            Prof.(Dr.) Mini M G<br></br>
            Principal, Govt. Model Engg. College
          </p>
        </div>
        <div className="placement-comittee-grid-left">
          <h3>Placement Officer</h3>
        </div>
        <div className="placement-comittee-grid-right">
          <Contact
            name="Ms. Aparnadevi P S"
            position="Training and Placement Officer"
            phNum="+91 94472 49042"
            emailId="pc@mec.ac.in"
          />
        </div>
        <div className="placement-comittee-grid-left">
          <h3>Student Coordinators</h3>
        </div>
        <div className="placement-comittee-grid-right">
          <Contact
            name="Adithya V"
            phNum="+91 7306555378"
            emailId="adithyav@mec.ac.in"
          />
          <Contact
            name="Ananya Nair"
            phNum="+91 8734052003"
            emailId="ananyadnair@mec.ac.in"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
