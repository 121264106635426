import React from "react";
import { Link } from "react-router-dom";
import { INTERNSHIPS } from "../../../config/routes";

// import axios from "axios";
// import { ApiRoot } from "../../../config/api";

import LazyImage from "../../common/LazyImage/LazyImage";
// import CompanyRecruit from "../CompanyRecruit";
// import { range } from "../../../config/utilities";
import Table from "./Table";
import "./PlacementStats.css";
// import { IPlacement } from "../../../assets/interfaces";

const PlacementStats = () => {
  // const [year, setYear] = useState(2022);
  // const [yearStats, setYearStats] = useState<IPlacement[]>([]);

  // const fetchYearStats = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const targetYear = Number(e.target.value);
  //   setYear(targetYear);

  //   axios
  //     .get(`${ApiRoot}/placement?type_placement=placement&year=${targetYear}`)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setYearStats(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   axios
  //     .get(`${ApiRoot}/placement?type_placement=placement&year=${year}`)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setYearStats(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [year]);

  return (
    <div className="placement-stats">
      <h1 className="section-heading">Placement Statistics</h1>
      <div className="section-heading-underline"></div>
      <p className="custom-p special_p">
        Govt. Model Engineering College has always been the benchmark for
        excellence when it comes to recruitments. We are proud to have played
        host to the biggest names in the industry as part of internships and
        recruitment drives. The year witnessed an outstanding placement season
        ensuring 75% placements and tech tycoons such as D.E. Shaw & Co.,
        FactSet, Microsoft, Broadcom, Oracle and GE Healthcare stood out among
        the prominent recruiters.
      </p>
      <Link to={INTERNSHIPS} className="custom-a statistics-button">
        Internship Statistics
        <LazyImage
          src={require("../../../assets/Placement/right-arrow.png")}
          alt="Right Arrow"
        />
      </Link>
      {/* <div className="placement-departments-container">
        <div className="placement-department">
          <div className="round-circle color-1"></div>
          <p>Computer Science Engineering</p>
        </div>
        <div className="placement-department">
          <div className="round-circle color-2"></div>
          <p>Electronics & Communication Engineering</p>
        </div>
        <div className="placement-department">
          <div className="round-circle color-3"></div>
          <p>Electrical & Electronics Engineering</p>
        </div>
        <div className="placement-department">
          <div className="round-circle color-4"></div>
          <p>Electronics & Biomedical Engineering</p>
        </div>
      </div> */}
      {/* <select
        className="placement-year pointer"
        onChange={fetchYearStats}
        value={year}
      >
        {range(2005, 2023).map((x) => (
          <option key={x}>{x}</option>
        ))}
      </select>
      <div className="placement-stats-grid">
        {yearStats.length !== 0 &&
          yearStats.map((stats, i) => (
            <CompanyRecruit
              key={i}
              name={stats.company.name}
              image={stats.company.logo}
              numOffers={Object.values(stats.no_of_offers) as number[]}
            />
          ))}
      </div> */}
      <Table/>
    </div>
  );
};

export default PlacementStats;
