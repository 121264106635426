import React from "react";
import { MembersProps } from "../../pages/Committees/Committees";
import AdminMembers from "../common/Photo/AdminMembers";

const iqacRules = [
  "The term of IQAC shall be one academic year. ",
  "IQAC shall conduct internal academic audits and produce the required documents and records to the External Auditor appointed by the University, on demand. ",
  "IQAC is also responsible for uploading monthly report, annual report and any other data required by the University. ",
  "IQAC will aim to develop and apply quality benchmarks/parameters for the various academic and administrative activities of the institution. ",
  "IQAC shall ensure equitable access to resources for various classes of the society, and to organize conduct of remedial coaching and bridge programmes as and when it is needed. ",
  "IQAC shall strive to incorporate modern methods into the teaching learning process. ",
];

const InternalQualityAssuranceCell = ({ members, title }: MembersProps) => {
  return (
    <div className="grid">
      <h2 className="custom-h2">{title}</h2>
      <p className="custom-p">
        Internal Quality Assurance Cell or Internal Audit Cell (IQAC/IAC)
        comprises of senior faculty members having representation from each
        department and is constituted by the Principal for the purpose of
        ensuring timely, efficient and progressive performance of academic and
        administrative tasks. A senior member of IQAC shall be its coordinator.
      </p>
      <ul>
        {iqacRules.map((rule, i) => (
          <li key={i} className="custom-p">
            {rule}
          </li>
        ))}
      </ul>
          <hr></hr>
      <a
                  href="https://drive.google.com/file/d/1VGrKN7YN8yJgRzTogfhTL14z1CfhLpp-/view?usp=sharing"
                
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  Minutes of IQAC meeting held on 12/06/2021
      </a>
      <hr></hr>
        <a
                  href="https://drive.google.com/file/d/13eCfecQvGqB9y_5zNwJLK-hDC3PKfOUs/view?usp=sharing"
                
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  Minutes of IQAC meeting held on 17/02/2021
      </a>
<hr></hr>
      {/* <p className="custom-p">NSS Faculty in charge </p> */}
      <AdminMembers members={members} title={title} />
    </div>
  );
};

export default InternalQualityAssuranceCell;
