import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { PG_BROCHURES } from "../../../config/routes";

import "./Breadcrumb.css";

const lookupTable: { [key: string]: string } = {
  committees: "Statutory Committees",
  cse: "Computer Engineering",
  eee: "Electrical & Electronics Engineering",
  ece: "Electronics Engineering",
  ebe: "Electronics & Biomedical Engineering",
  as: "Applied Science",
  me: "Mechanical Engineering",
  btech: "B. Tech",
  mtech: "M. Tech",
  mtech_sig_proc: "M. Tech Signal Processing",
  mtech_vlsi: "M. Tech VLSI",
  mtech_opto: "M. Tech Optoelectronics",
  [PG_BROCHURES.slice(1)]: "PG Brochures",
};

const changeTitle = (item: string) => {
  if (item in lookupTable) {
    return lookupTable[item];
  }
  return `${item.charAt(0).toUpperCase()}${item.slice(1)}`;
};

const Breadcrumb = () => {
  const location = useLocation();
  const x = location.pathname.split("/").filter((k) => k !== "");
  let y = ["Home"];
  for (let i = 0; i < x.length; i++) {
    y.push(">");
    y.push(changeTitle(x[i]));
  }
  // y.unshift("Home");

  return y.length > 1 ? (
    <div className="Breadcrumb-container">
      {y.map((item, id) =>
        item === ">" ? (
          <div className="Breadcrumb-divider" key={id}>
            {item}
          </div>
        ) : (
          <NavLink
            to={`/${x.slice(0, Math.floor(id / 2)).join("/")}`}
            className="Breadcrumb-navlink"
            key={id}
          >
            {item}
          </NavLink>
        )
      )}
    </div>
  ) : null;
};

export default Breadcrumb;
